import { AppContext } from "../../../context/Context";
import { useContext } from "react";
import locales from "../../../locale/locales.json";
import Popup from "reactjs-popup";

import TestPhoto from '../../../svg/test-photo.svg'
import EoFrame from '../../../svg/eo-frame.svg'
import TitleLogo from '../../../svg/title-logo.svg'
import backIcon from '../../../svg/back-icon-desktop-rules.svg'

function PartnerArticle({innerHTML, position, partner, readMore})
{
    const { lang, isMobile } = useContext(AppContext);
    
    function populateContent(titleSize="20px", descriptionSize="12px")
    {
      return readMore.map((section, index) => {
        return (
          <div className="section-wrapper" key={index}>
            <div className="rules-title" style={{fontSize: titleSize}}>
              {section.title}
            </div>
            {
              section.description.map((description, index) => {
                return (
                  <div className="rules-description" key={index} style={{fontSize: descriptionSize}}>
                    {description}
                  </div>
                )
              })
            }
          </div>
        )
      })
    }  
    
    return (
        <div>
            { !isMobile ?
            <div>
                <div className="sponsors">
                    <img className={position === "left"? "start" : "end"}  src={TitleLogo}/>
                    { position === "left"?
                        <h2 className="sponsor left-sponsor"> { partner } </h2> :
                        <div class="right-text">{ partner }</div>
                    }
                </div>
                <div style={styles.container} className={position === "right"? "second-image" : null}>
                    <img src={TestPhoto} alt="Background" style={styles.image} />
                    <img src={EoFrame} alt="Overlay" style={styles.overlayImage} />
                    <div style={styles.textContainer}>
                        <p style={styles.text} className="partner-text"> 
                        {
                            innerHTML.map((item, index) => {
                                return (
                                <p key={index}> {item} </p>
                                )
                            })
                        }
                        </p>
                    </div>
                    {
                    readMore? 
                    <Popup trigger={<button className="read-more"> Read More </button>} modal nested>
                    {
                        close => (
                        <div className="faq-modal">
                            <div className="rules-button-wrapper">
                            <button className="close-modal-button" onClick=
                                {() => close()}>
                                <img src={backIcon} className="rules-back-icon"/>
                            </button>
                            </div>
                            <div className="rules-wrapper">
                            {
                                populateContent('2dvw', '1.6dvw')
                            }
                            </div>
                        </div>
                        )
                    }
                    </Popup> : null
                    }
                </div>
            </div>
            : 
            <div>
                <div className="sponsors">
                    <img className={position === "left"? "start" : "end-mobile"} src={TitleLogo}/>
                    { position === "left"?
                        <h2 className="left-sponsor-mobile"> { partner } </h2> :
                        <div class="right-text-mobile">{ partner }</div>
                    }
                </div>
                <div style={styles.container} className={position === "right"? "second-image" : null} >
                    <img src={TestPhoto} alt="Background" style={styles.image} />
                    <img src={EoFrame} alt="Overlay" style={styles.overlayImage} />
                    <div style={styles.textContainer}>
                        <p style={styles.text} className="partner-text-mobile"> 
                        {
                            innerHTML.map((item, index) => {
                                return (
                                <p key={index}> {item} </p>
                                )
                            })
                        }
                        </p>
                    </div>
                    {
                    readMore? 
                    <Popup trigger={<button className="read-more"> Read More </button>} modal nested>
                    {
                        close => (
                        <div className="faq-modal">
                            <div className="rules-button-wrapper">
                            <button className="close-modal-button-content" onClick=
                                {() => close()}>
                                <img src={backIcon} className="rules-back-icon"/>
                            </button>
                            </div>
                            <div className="rules-wrapper">
                            {
                                populateContent('2dvw', '1.6dvw')
                            }
                            </div>
                        </div>
                        )
                    }
                    </Popup> : null
                    }
                </div>
            </div>
            }
        </div>
    )
}

const styles = {
    container: {
      position: 'relative',
      height: '30%',
    },
    image: {
      width: '100%',
      height: '50%',
      objectFit: 'cover',
      opacity:0.5,
      filter: 'brightness(50%)'
    },
    overlayImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    textContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      color: 'white', 
      textAlign: 'left',
      marginTop: '20px'
    },
  };

export default PartnerArticle;