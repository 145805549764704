import OrangeGradientText from '../../components/OrangeGradientText/OrangeGradientText';
import './Team.css';
import locales from '../../locale/locales.json';
import { AppContext } from '../../context/Context';
import { useContext } from 'react';
import Profile from '../../components/Profile/Profile';
import teamdeco from '../../svg/teamdeco.svg';
import lines from '../../svg/design-home-lines.svg';
function Team() {
    const { lang, isMobile } = useContext(AppContext);
    const { title, people } = locales[lang].team;
    
    const personList = () => {
		return people.map((person, index) => {
			person = {...person, ...locales.both.team?.people[person.name]};
			return <div className={index === 0? "full-width" : ""}> 
                        <Profile key={index} person={person}/>
                    </div>;
		});
	}
    return (
        <div className="team-top">
            <img src={lines} alt="" className='team-unnecesary-deco-left'/>
            <img src={lines} alt="" className='team-unnecesary-deco-right'/>
            <div className='team-header'>
				<OrangeGradientText
                    text={title}
                	fontSize={isMobile? "14vw" : "7.7vw"}
                    fancy={true}
                  />
			</div>
            <div className='team-people-container'>
                {personList()}
                <div className={isMobile? "team-people-container-deco" :'team-people-container-deco margin-top-deco'}>
                    <img src={teamdeco} alt="" className='team-deco-img'/>
                </div>
            </div>
        </div>
    );
}

export default Team;