import React, { useState, useContext } from "react";
import "./ReadMore.css";
import locale from "../../../../locale/locales";
import { AppContext } from "../../../../context/Context";

function ReadMore({ text }) {
  const [readMore, setReadMore] = useState(false);
  const { lang } = useContext(AppContext);

  return (
    <div
      className={`read-more-content-container ${readMore ? "expanded" : ""}`}
    >
      <div className="read-more-text-container">
        {readMore ? text : `${text.substring(0, 145) + "..."}`}
      </div>
      <button
        className="read-more-button-container"
        onClick={() => setReadMore(!readMore)}
      >
        {readMore
          ? locale[lang]["about"]["button-less"]
          : locale[lang]["about"]["button-more"]}
      </button>
    </div>
  );
}

export default ReadMore;
