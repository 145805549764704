import React from "react";

import "./AboutComponent.css";
import ReadMore from "../ReadMore/ReadMore";

import eoFrame from "../../../../svg/eo-frame.svg";
import design from "../../../../svg/design-about.svg";
import titleLogo from "../../../../svg/title-logo.svg";
import lineDesign from "../../../../svg/line-design.svg";

function AboutComponent({ title, text, imgSrc, reverse }) {
  return (
    // check if the component is reversed and display the content accordingly
    <div className={`about-container ${reverse ? "reverse" : ""}`}>
      <div className="header-about-container">
        <div className="header-about-name-container">{title}</div>
        <div className="header-about-design-wrapper">
          <img
            className="header-design-about-container"
            src={design}
            alt="design"
          />
        </div>
      </div>
      <div className="about-content-container">
        <ReadMore text={text} />
      </div>
      <div className="img-container">
        <div className="overlap-group-img">
          <div className="rectangle-background"></div>
          <img className="eo-frame-img" src={eoFrame} alt="eo-frame" />
          <img className="photo-img" src={imgSrc} alt="about-picture" />
        </div>
        <img className="line-design-img" src={lineDesign} alt="line-design" />
      </div>
    </div>
  );
}

export default AboutComponent;
