import React from "react";
import { useContext } from "react";

import "./About.css";
import { AppContext } from "../../context/Context";
import AboutComponent from "./components/AboutComponent/AboutComponent";
import AboutComponentDesktop from "./components/AboutComponentDesktop/AboutComponentDesktop";
import Team from '../Team/Team'

import text from "../../locale/text";
import testPhoto from "../../svg/test-photo.svg";

function About() {
  const { lang, isMobile } = useContext(AppContext);

  if (isMobile) {
    // mobile setup
    return (
      <div className="about-page-container">
        {/* Components that will receive title, text and image and will
        display them in a container - information about the association */}
        <AboutComponent
          title={text["About"][0]["title"][lang]}
          text={text["About"][0]["content"][lang]}
          imgSrc={testPhoto}
          reverse={false}
        />
        <AboutComponent
          title={text["About"][1]["title"][lang]}
          text={text["About"][1]["content"][lang]}
          imgSrc={testPhoto}
          reverse={true}
        />

        <AboutComponent
          title={text["About"][2]["title"][lang]}
          text={text["About"][2]["content"][lang]}
          imgSrc={testPhoto}
          reverse={false}
        />

        <div className="team-wrapper-mobile">
          <Team />
        </div>
      </div>
    );
  } else {
    // desktop setup
    return (
      <>
        {/* Components that will receive title, text and image and will
        display them in a container - information about the association */}
        <AboutComponentDesktop
          title={text["About"][0]["title"][lang]}
          text={text["About"][0]["content"][lang]}
          imgSrc={testPhoto}
          reverse={false}
        />
        <AboutComponentDesktop
          title={text["About"][1]["title"][lang]}
          text={text["About"][1]["content"][lang]}
          imgSrc={testPhoto}
          reverse={true}
        />

        <AboutComponentDesktop
          title={text["About"][2]["title"][lang]}
          text={text["About"][2]["content"][lang]}
          imgSrc={testPhoto}
          reverse={false}
        />

        <div className="team-wrapper">
          <Team />
        </div>

      </>
    );
  }
}

export default About;
