import "./AboutComponentDesktop.css";
import ReadMore from "../ReadMore/ReadMore";
import designLines from "../../../../svg/about-design-vector.svg";
import horizontalLines from "../../../../svg/line-design.svg";
import verticalLines from "../../../../svg/vertical-lines.svg";

function AboutComponentDesktop({ title, text, imgSrc, reverse }) {
  if (reverse) {
    // image on the left side and text on the right side
    return (
      <div className="about-desktop-container">
        <div className="about-text-desktop">
          <div className="title-text-desktop">{title}</div>
          <ReadMore text={text} />
        </div>
        <div className="design-lines-desktop">
          <img
            className="design-lines-desktop-reverse"
            src={designLines}
            alt="design-lines"
          />
        </div>
        <div className="about-img-container">
          <div className="overlap-group-img-desktop">
            <img
              className="photo-img-desktop"
              src={imgSrc}
              alt="about-picture"
            />
          </div>
        </div>
      </div>
    );
  } else {
    // image on the right side and text on the left side
    return (
      <div className="about-desktop-container">
        <div className="about-img-container">
          <div className="overlap-group-img-desktop">
            <img
              className="photo-img-desktop"
              src={imgSrc}
              alt="about-picture"
            />
          </div>
          <div className="vertical-lines-desktop">
            <img
              className="vertical-lines-img"
              src={verticalLines}
              alt="vertical-lines"
            />
          </div>
          <div className="horizontal-lines-desktop">
            <img
              className="horizontal-lines-img"
              src={horizontalLines}
              alt="horizontal-lines"
            />
          </div>
        </div>
        <div className="about-text-desktop">
          <div className="title-text-desktop">{title}</div>
          <ReadMore text={text} />
        </div>
        <div className="design-lines-desktop">
          <img
            className="design-lines-desktop-non-reverse"
            src={designLines}
            alt="design-lines"
          />
        </div>
      </div>
    );
  }
}

export default AboutComponentDesktop;
