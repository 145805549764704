import './FAQ.css';

import futuristicLines from "../../svg/design-lines-90-deg.svg";
import arrow from '../../svg/arrow-main.svg'
import arrowAlt2 from '../../svg/arrow-alt2.svg'
import lineArrow from "../../svg/line-arrow.svg"
import linesFAQ from '../../svg/lines-FAQ.svg'
import backRulesDestop from '../../svg/back-icon-desktop-rules.svg'

import OrangeGradientText from "../../components/OrangeGradientText/OrangeGradientText"
import OrangeGradientButton from "../../components/OrangeGradientButton/OrangeGradientButton"

import { AppContext } from "../../context/Context";
import { useContext, useState } from "react"; 
import locales from '../../locale/locales.json'
import Popup from 'reactjs-popup';
import Card from './Card/Card';

function FAQ() {
  
  const { lang, isMobile } = useContext(AppContext);
  const [rulesActive, setRulesActive] = useState(false);

  function onClickRules()
  {
    setRulesActive(!rulesActive);
  }

  function onClickApply()
  {
    window.location.href = locales.both.global.formLink
  }

  function onClickContact()
  {
    window.location.href = "/contact";
  }

  function populateCards(titleSize, descriptionSize, width="80dvw", height="fit-content")
  {
    return (
      locales[lang].FAQ.questions.map((card, index) => {
      return <Card key={index} 
                   title={card.title} 
                   description={card.description} 
                   titleSize={titleSize} 
                   descriptionSize={descriptionSize}
                   width={width}
                   height={height}
                   image={locales.both.FAQ.questionsImages[index]} />
    })
    )
  }

  function populateRules(sectionNameSize="12px", titleSize="20px", descriptionSize="12px")
  {
    return locales[lang].FAQ.rules.map((rule, index) => {
      return (
        <div className="section-wrapper" key={index}>
          <div className="rules-section" style={{fontSize: sectionNameSize}}>
            {locales[lang].FAQ.sectionName + ' ' + (index + 1)}
          </div>
          <div className="rules-title" style={{fontSize: titleSize}}>
            {rule.title}
          </div>
          {
            rule.description.map((description, index) => {
              return (
                <div className="rules-description" key={index} style={{fontSize: descriptionSize}}>
                  {description}
                </div>
              )
            })
          }
        </div>
      )
    })
  }

  return (
    <div>
      { isMobile ?
      <div>
        <div className="faq-title-wrapper-mobile">
          <div className="faq-lines-container-left-mobile">
            <img src={futuristicLines} className='faq-line-design-left-mobile' />
          </div>
          <div className="faq-title-container-mobile">
            <OrangeGradientText text={locales[lang].FAQ.title} fontSize="9dvw" reverse={true} />
          </div>
          <div className="faq-lines-container-right-mobile">
            <img src={futuristicLines} className='faq-line-design-right-mobile' />
          </div>
        </div>
        {
        !rulesActive ?
        <div className="faq-top-section-mobile">
          <div className="faq-top-section-text-mobile">
            {locales[lang].FAQ.description}
          </div>
          
          <img src={lineArrow} className="faq-line-arrow-mobile"/>
          
          <button className="faq-rules-button-mobile" onClick={onClickRules}>
            <div className="rules-button-icon-wrapper-mobile">
              <img src={arrow} className="rules-button-icon-mobile"/>
            </div>
            {locales[lang].FAQ.rulesButton}
          </button>

          <img src={lineArrow} className="faq-line-arrow-mobile"/>
          
          <button className="faq-contact-button-mobile" onClick={onClickContact}>
            <div className="contact-button-icon-wrapper-mobile">
              <img src={arrowAlt2} className="contact-button-icon-mobile"/>
            </div>
            {locales[lang].FAQ.contactButton}
          </button>
          <img src={linesFAQ} className="faq-lines-FAQ-mobile"/>
        </div> :

        <div className="rules-section-mobile">
          <div className="rules-back-mobile" onClick={onClickRules}>
            <img src={arrow} className="rules-back-icon-mobile"/>
          </div>
          <div className="rules-container-mobile">
            {
              populateRules()
            }
          </div>
        </div>
        }

        <div className="faq-main-section-mobile">
          {
            populateCards("16px", "12px")
          }
        </div>

        <div className="faq-bottom-section-mobile">
          <OrangeGradientText text={locales[lang].FAQ.endTitle} fontSize="36px" reverse={true} />
          <OrangeGradientButton text={locales[lang].FAQ.endButton} onClick={onClickApply} fontSize="36px"/>
        </div>
      </div> :
      
      <div className="faq-main">
        <div>
          <div className="faq-top-section">
            <div className="faq-title-wrapper">
              <OrangeGradientText text={locales[lang].FAQ.title} fontSize="3.3dvw" reverse={true} />
              <div className="faq-description">
                {locales[lang].FAQ.description}
              </div>
            </div>
            <OrangeGradientButton text={locales[lang].FAQ.endButton} onClick={onClickApply} fontSize="3.3dvw"/>
          </div>
          <div className="faq-buttons-section">
            <Popup trigger=
            {
              <button className="faq-rules-button" onClick={onClickRules}>
                <div className="rules-button-icon-wrapper">
                  <img src={arrow} className="rules-button-icon"/>
                </div>
                {locales[lang].FAQ.rulesButton}
              </button>
            } modal nested>
              {
                close => (
                  <div className="faq-modal">
                    <div className="rules-button-wrapper">
                      <button className="close-modal-button" onClick=
                          {() => close()}>
                        <img src={backRulesDestop} className="rules-back-icon"/>
                      </button>
                    </div>
                    <div className="rules-wrapper">
                      {
                        populateRules('1.7dvw', '2dvw', '1.6dvw')
                      }
                    </div>
                  </div>
                )
              }
            </Popup>

            <button className="faq-contact-button" onClick={onClickContact}>
                <div className="contact-button-icon-wrapper">
                  <img src={arrowAlt2} className="contact-button-icon"/>
                </div>
                {locales[lang].FAQ.contactButton}
            </button>
          </div>
          <div className="faq-main-section">
            {
              populateCards("16px", "12px", "320px", "160px")
            }
          </div>
        </div>
      </div>
      }
    </div>
  );
}

export default FAQ;