import React, { useState, useContext, useRef } from 'react';
import './ContactForm.css';
import emailjs from '@emailjs/browser';
import locales from '../../../locale/locales.json';
import { AppContext } from '../../../context/Context';



const ContactForm = () => {
    const WAITING = 0;
    const SUCCESS = 1;
    const ERROR = 2;

    const [formStatus, setFormStatus] = useState(WAITING);

    const form = useRef(null);

    const service = process.env.REACT_APP_SERVICE;
    const template = process.env.REACT_APP_TEMPLATE;
    const user = process.env.REACT_APP_USER;

    console.log(service, template, user);

    const { lang } = useContext(AppContext);
	const {
        formTitle,
        emailText,
        placeholderEmail,
        nameText,
        placeholderName,
        questionText,
        placeholderQuestion,
        buttonText,
        success,
        error
    } = locales[lang].contact.form;
    const sendEmail = (e) => {
        e.preventDefault();
        console.log(form);
        const {reply_to, from_name, message} = e.target;
        if (!reply_to.value) {
            reply_to.classList.add("contact-form-error-field");
        } else {
            reply_to.classList.remove("contact-form-error-field");
        }
        if (!from_name.value) {
            from_name.classList.add("contact-form-error-field");
        } else {
            from_name.classList.remove("contact-form-error-field");
        }
        if (!message.value) {
            message.classList.add("contact-form-error-field");
        }
        else {
            message.classList.remove("contact-form-error-field");
        }

        if (from_name.value === "" || reply_to.value === "" || message.value === "") {
            return;
        }
        emailjs.send(service, template, {
            from_name: from_name.value,
            reply_to: reply_to.value,
            message: message.value
        }, user)
            .then((r) => {
                setFormStatus(SUCCESS);
                from_name.value = "";
                reply_to.value = "";
                message.value = "";
            }, (e) => {
                console.log(e);
                setFormStatus(ERROR);
            });
    }

    return (
        <form ref={form} onSubmit={sendEmail} className="contact-form">
            <div className='contact-form-title'>
                {formTitle}
            </div>

            <div className="contact-form-names-container">
                <div className='contact-form-name-container'>
                    <label className="contact-form-name-label" htmlFor="name">
                        {emailText}
                    </label>
                    <input
                        type="text"
                        id="name"
                        className="contact-form-name-field contact-form-field-text"
                        placeholder={placeholderEmail}
                        name="reply_to"
                    />
                </div>
                <div className='contact-form-name-container'>
                    <label className="contact-form-name-label" htmlFor="name">
                        {nameText}
                    </label>
                    <input
                        type="text"
                        id="name"
                        className="contact-form-name-field contact-form-field-text"
                        placeholder={placeholderName}
                        name="from_name"
                    />
                </div>
            </div>
            <div className="contact-form-question-container">
                <label className="contact-form-name-label" htmlFor="question">
                    {questionText}
                </label>
                <textarea
                    id="question"
                    className="contact-form-question-field contact-form-field-text"
                    placeholder={placeholderQuestion}
                    name="message"
                />
            </div>
            <div className='contact-form-footer-container'>
                {formStatus === SUCCESS && <div className="contact-form-success">{success}</div>}
                {formStatus === ERROR && <div className="contact-form-error">{error}</div>}
                <input 
                    type="submit"
                    className="contact-form-button"
                    value={buttonText}>
                </input>
            </div>
        </form>
    );
};

export default ContactForm;