import { useRef, useState, useEffect } from "react"
import { AppContext } from "./Context"

function ContextProvider(props)
{
    
    const [lang, setLang] = useState(localStorage.getItem("eo12-lang") || "ro") // if we have lang in localstorage, init it to that 
    const [isMobile, setIsMobile] = useState(false)
    const values = {lang, isMobile, setLang, setIsMobile}


    // if lang gets changed, store it in localstorage
    useEffect(() => {
        localStorage.setItem("eo12-lang",lang);
    },[lang])

    return (
        <AppContext.Provider value={values}>
            {props.children}
        </AppContext.Provider>
    )

}
export default ContextProvider