import Profile from '../../components/Profile/Profile';
import ContactForm from './ContactForm/ContactForm';
import locales from '../../locale/locales.json';
import { AppContext } from '../../context/Context';
import { useContext } from 'react';
import './Contact.css';
import OrangeGradientText from '../../components/OrangeGradientText/OrangeGradientText';
import lines from '../../svg/design-home-lines.svg';
function Contact() {
	const { lang, isMobile } = useContext(AppContext);

	const { title, people } = locales[lang].contact;
	const personList = () => {
		return people.map((person, index) => {
			person = {...person, ...locales.both.contact.people[person.name]};
			return <Profile key={index} person={person} />;
		});
	}
	return (
		<div className='contact-top-level-page'>
			<img src={lines} alt="" className='contact-lines-top' />
			<img src={lines} alt="" className='contact-lines-bot' />
			<div className='contact-header'>
				<OrangeGradientText
                    text={title}
                	fontSize={isMobile? "14vw" : "7.7vw"}
                    fancy={true}
                  />
			</div>
			<div className='contact-flex'>
				<div className='contact-people-container'>
					{personList()}
				</div>
				<ContactForm />
			</div>
		</div>
	);
}

export default Contact;