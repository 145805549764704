import About from "../pages/About/About";
import Contact from "../pages/Contact/Contact";
import FAQ from "../pages/FAQ/FAQ";
import Game from "../pages/Game/Game";
import Home from "../pages/Home/Home";
import Partners from "../pages/Partners/Partners";
import Team from "../pages/Team/Team";

import { createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/rules-FAQ",
    element: <FAQ />,
  },
  {
    path: "/game",
    element: <Game />,
  },
  {
    path: "/partners",
    element: <Partners />,
  },
  {
    path: "/team",
    element: <Team />,
  },
]);

export default router;
