import  "./Partners.css";
import DesignHomeLines from  "../../svg/design-lines-90-deg.svg"
import TestPhoto from '../../svg/test-photo.svg'
import EoFrame from '../../svg/eo-frame.svg'
import TitleLogo from '../../svg/title-logo.svg'
import FuturisticLinesSimple from '../../svg/futuristic-lines-simple.svg'
import PartnersList from "./components/PartnersTudor";
import {AppContext} from '../../context/Context'
import {useContext} from 'react'
import locales from '../../locale/locales.json'
import PartnerArticle from "./components/PartnerArticle";


export default function Partners() 
{
  const { lang, isMobile} = useContext(AppContext);
  const dvloper = [ locales[lang].partners.description1, locales[lang].partners.description1_1]
  const bitdefender = [ locales[lang].partners.description2, <p> </p>, locales[lang].partners.description2_1]
  const ortec = [ locales[lang].partners.description3]
  const softserve = [ locales[lang].partners.description4]

  return (
    <div>
    { !isMobile ?
    <div class="title-wrapper">
      <div className="title-partners"> { locales[lang].partners.title } </div>
      <img src={DesignHomeLines} class="left design-line" />
      <img src={DesignHomeLines} class="right design-line" style={{ transform: 'rotate(-180deg)' }}/>
      <PartnerArticle innerHTML={dvloper} position="left" partner={locales[lang].partners.partner1} readMore={locales[lang].partners.content1}/>
      <PartnerArticle innerHTML={bitdefender} position="right" partner={locales[lang].partners.partner2}/>
      <PartnerArticle innerHTML={ortec} position="left" partner={locales[lang].partners.partner3}/>
      {/* <PartnerArticle innerHTML={softserve} position="right" partner={locales[lang].partners.partner4}/> */}

      <PartnersList/>
    </div>
      :
    <div class="title-wrapper-mobile">
      <div className="title-partners"> { locales[lang].partners.title } </div>
      <img src={DesignHomeLines} class="left-mobile design-line" />
      <img src={DesignHomeLines} class="right-mobile design-line" style={{ transform: 'rotate(-180deg)' }}/>
      <PartnerArticle innerHTML={dvloper} position="left" partner={locales[lang].partners.partner1} readMore={locales[lang].partners.content1} />
      <PartnerArticle innerHTML={bitdefender} position="right" partner={locales[lang].partners.partner2}/>
      <PartnerArticle innerHTML={ortec} position="left" partner={locales[lang].partners.partner3}/>
      {/* <PartnerArticle innerHTML={softserve} position="right" partner={locales[lang].partners.partner4}/> */}

      <PartnersList/>
    </div>
    }
  </div>
  );
} 