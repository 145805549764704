export const partners = {
    main: [
      {
        name: "Bitdefender",
        logo: "https://imgur.com/5TRtPg8.png",
        logoDark: "https://imgur.com/5TRtPg8.png",
        url: "https://www.bitdefender.ro/",
      },
      {
        name: "dvloper.io",
        logo: "https://imgur.com/jKQswqu.png",
        logoDark: "https://imgur.com/jKQswqu.png",
        url: "https://dvloper.io/",
      }
    ],
    challenge: [
      {
        name: "Ortec",
        logo: "https://imgur.com/tLLPnKK.png",
        logoDark: "https://imgur.com/g27HqVY.png",
        url: "https://www.ortec.com/",
      }
    ],
    sponsors: [
      {
        name: "Softserve",
        logo: "https://imgur.com/pJ3CfzK.png",
        logoDark: "https://imgur.com/5sKbd3A.png",
        url: "https://www.softserveinc.com/en-us",
      }
    ],
    anual: [
      {
        name: "Mario Pizza",
        logo: "https://imgur.com/tC7Cd8x.png",
        logoDark: "https://imgur.com/I0PfWTp.png",
        url: "https://mariospizza.ro"
      },
      {
        name: "V7 Startup Studio",
        logo: "https://imgur.com/ebuNhRa.png",
        logoDark: "https://imgur.com/tHfTsjg.png",
        url: "https://v7studio.com/"
      },
      {
        name: "Uncle John",
        logo: "https://imgur.com/WAHM9lX.png",
        logoDark: "https://imgur.com/WAHM9lX.png",
        url: "https://unclejohn.ro"
      }
    ],
    barter: [
      {
        name: "Uncle John",
        logo: "https://imgur.com/zS1ipbq.png",
        logoDark: "https://imgur.com/zS1ipbq.png",
        url: "https://unclejohn.ro"
      }
    ],
    media: [
      {
        name: "BEST",
        logo: "https://imgur.com/TRllS1i.png",
        logoDark: "https://imgur.com/zcIUG4b.png",
        url: "https://www.best.eu.org/aboutBEST/structure/lbgView.jsp?lbginfo=bc"
      },
      {
        name:"Sindicatul Studentilor de Cibernetica",
        logo: "https://imgur.com/omAkSxg.png",
        logoDark: "https://imgur.com/v5STjii.png",
        url: "https://www.facebook.com/sscibernetica"

      },
      {
        name: "Codecamp",
        logo: "https://imgur.com/tLQgxBo.png",
        logoDark: "https://imgur.com/U8QtPfU.png",
        url: "https://codecamp.ro/"
      }
    ]
  };
  