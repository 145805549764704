import './Card.css';

function Card({ title, description, height, width, titleSize = "16px", descriptionSize = "12px", image }) {
  return (
    <div className="faq-card" style={{height: height, width: width}}>
        <div className="top-card-wrapper">
            <div className="top-card-icon-wrapper">
                <img src={`${image}`} className="top-card-icon" alt="user"/>
            </div>

            <div className="top-card-title" style={{fontSize: titleSize}}>{title}</div>
        </div>
        <div className="bottom-card-wrapper">
            <div className="bottom-card-description" style={{fontSize: descriptionSize}}>
                {description}
            </div>
        </div>
    </div>
  );
}

export default Card;