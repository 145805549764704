import './Prize.css'

function Prize({ title, prize, fontSize = "32px" })
{
    return (
        <div className="prize-wrapper">
            <div className="prize-title" style={{fontSize: fontSize}}>
                {title}
            </div>
            <div className="prize" style={{fontSize: fontSize}}>
                {prize}
            </div>
        </div>
    )
}

export default Prize