import React from "react";
import "./App.css";
import Navbar from "./global/Navbar/Navbar";
import { RouterProvider } from "react-router-dom";
import router from "./router/router";
import Footer from "./global/Footer/Footer";
import { AppContext } from "./context/Context";

function App() {
  //init states
  const [width, setWidth] = React.useState(window.innerWidth);
  const { isMobile, setIsMobile } = React.useContext(AppContext);

  //check if on mobile (responsive)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  setIsMobile(width <= 768); // use this in your components to check if on mobile device for js scripts

  return (
    <div className="App">
      <Navbar mobile={isMobile} />
      <RouterProvider router={router} />
      <div style={{ height: "150px" }}></div>
      {/* this is approximately the height of the footer */}
      <Footer />
    </div>
  );
}

export default App;
