import React from 'react'
import './Profile.css';

import heptrail from '../../svg/heptrail.svg';
const Profile = ({person}) => {
    const {name, role, phone, email, imageUrl} = person;
    const isContact = email !== undefined;
    return (
        <div className={`profile-component ${!isContact ? "profile-team" : ""}`}>
            <div className={`profile-heptagon-container`}>
                <img src={heptrail}
                    alt=""
                    className='profile-heptrail-right'
                />
                <img src={heptrail}
                    alt=""
                    className='profile-heptrail-left'
                />
                {/* Ok m-am suparat, nu se scalau cum trebuie mask-urile */}
                <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 225 225"
                    xmlns="http://www.w3.org/2000/svg">
                    <defs>
                    <mask id="heptagon-mask">
                    {/* lerp with t=0.9 */}
                    <path d="M112.4991 5.5836L196.0901 45.8387L216.7349 136.2911L158.8883 208.8280H66.1099L8.2634 136.2911L28.9086 45.8387L112.4991 5.5836Z" fill="white"/>

                    </mask>
                    </defs>
                    <path d="M112.5 0L200.456 42.3574L222.179 137.534L161.312 213.859H63.6881L2.82061 137.534L24.544 42.3574L112.5 0Z" fill="#0B0D3D"/>
                    <path d="M112.499 6.20398L195.605 46.2255L216.13 136.153L158.619 208.269H66.379L8.86819 136.153L29.3936 46.2255L112.499 6.20398Z" fill="#FEFEFA"/>

                    <image
                        href={imageUrl}
                        width="100%"
                        height="100%"
                        preserveAspectRatio="xMidYMid slice"
                        mask="url(#heptagon-mask)"
                    />
                </svg>
            </div>
            <div className={`profile-full-description`}>
                <div className='profile-text profile-bold profile-name'>{name}</div>
                <div className='profile-text'>{role}</div>
                {isContact ? (<a href={`tel:+4${phone}`}><div className='profile-text'>{phone}</div></a>) : null}
                {isContact ? (<a href={`mailto:${email}`}><div className='profile-text'>{email}</div></a>) : null}
            </div>
        </div>
    );
}

export default Profile;