import React from 'react'
import "./Footer.css"
import locales from "../../locale/locales.json"
import phoneSVG from "../../svg/ic_baseline-phone.svg"
import pinSVG from "../../svg/mdi_location.svg"

export default function Footer() {

    return <footer>
        <ul>
            {locales.both.footer.links.map((link, index) => {
                return <a target='_blank' href={link.link} key={index}><img src={`${link.name}.svg`} alt={link.name}></img></a>
            })}
        </ul>
        <div className='footer-line'>
            <img src={pinSVG} alt='location'></img>
            <a target='_blank' href={locales.both.footer.geolocation}>{locales.both.footer.location}</a>
        </div>
        <div className='footer-line'>
            <img src={phoneSVG} alt='phone'></img>
            <a target="_blank" href={`rel:${locales.both.footer.phone}`}>{locales.both.footer.phone}</a>
        </div>
    </footer>
}