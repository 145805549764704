
import React from "react";
import "./Game.css";
import gameData from "../../locale/game.json";
import Popup from "reactjs-popup";
import locales from "../../locale/locales.json"
import { AppContext } from "../../context/Context";
import arrow from '../../svg/arrow-alt2.svg'

import buttonArrow from "../../svg/button-arrow.svg";
import sideSvg from "../../svg/gameSide.svg"
import sideSvgL from "../../svg/gameSideL.svg"


function Game() {
  //INIT STATES
  const [gameState, setGameState] = React.useState(0); // 0 = game not started ; 1 = playing ; 2 = game ended
  const [correctAnswers, setCorrectAnswers] = React.useState([]); // store answers here, as objects
  const inputEl = React.useRef(""); // store input here
  const [timer, setTimer] = React.useState(1); // timer here
  const [gameTopic, setGameTopic] = React.useState({}); // we store the topic object here, with title, prog-title and answers array
  const [myInterval, setMyInterval] = React.useState();
  const [highScore, setHighScore] = React.useState(0);
  const [score, setScore] = React.useState(0);
  const { lang } = React.useContext(AppContext);


  //INIT CONSTS
  const BASE_TIMER = 30; // in seconds
  const SIMILARITY_TRESHOLD = 0.7; // how similar an answer must be to be counted. 1 means strings have to be identical, 0 means anything goes

  //HANDLE STARTING THE GAME
  async function startGame() {
    setCorrectAnswers([]); //reset answers
    setTimer(BASE_TIMER);
    setGameState(1);
    setMyInterval(
      setInterval(() => {
        setTimer((prevSec) => prevSec - 1);
      }, 1000)
    );
    await setGameTopic(
      gameData.topics[Math.floor(Math.random() * gameData.topics.length)]
    );
  }

  //update highscore right after we get our topic!
  React.useEffect(() => {
    const savedHighscore = localStorage.getItem(gameTopic["dev-title"]);
    const initialHighscore = JSON.parse(savedHighscore);
    setHighScore(initialHighscore || 0);
  }, [gameTopic]);

  // we use these to see how similar two strings are, returns a number between 0 and 1 (0 == nothing alike , 1 == same string)
  function similarity(s1, s2) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength === 0) {
      return 1.0;
    }
    return (
      (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
    );
  }

  function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i === 0) costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) !== s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }

  // this closes the interval when it reaches 0, so we can restart the game
  React.useEffect(() => {
    if (timer === 0) {
      if (myInterval !== 0) {
        // console.log(myInterval+"cleared");
        clearInterval(myInterval);
      }
      // console.log("clearedInterval");
      let sum = 0;
      correctAnswers.forEach((answer) => {
        sum += answer.score;
      });
      if (sum > highScore) {
        setHighScore(sum);
        localStorage.setItem(gameTopic["dev-title"], sum);
      }
      setScore(sum);
      console.log(highScore);
      setGameState(2);
    }
  }, [timer]);

  function handleSubmit() {
    // console.log(inputEl.current.value)

    if (timer > 0 && inputEl.current.value !== "") {
      let alreadyFound = false;
      const ans = inputEl.current.value; // we store this so we can still use it even after it gets cleared, cause js is weird
      correctAnswers.forEach((el) => {
        console.log(el.title);
        if (
          similarity(
            inputEl.current.value.toLowerCase().trim(),
            el.title.toLowerCase().trim()
          ) >= SIMILARITY_TRESHOLD
        ) {
          alreadyFound = true;
          return;
        }
      });
      let foundAnswer = false;
      if (!alreadyFound) {
        gameTopic.answers.forEach((el) => {
          console.log(
            inputEl.current.value,
            el.answer,
            similarity(inputEl.current.value, el.answer)
          );
          if (
            similarity(
              inputEl.current.value.toLowerCase().trim(),
              el.answer.toLowerCase().trim()
            ) >= SIMILARITY_TRESHOLD
          ) {
            setCorrectAnswers((prevArr) => {
              let newArr = [];
              prevArr.forEach((arrEl) => {
                newArr.push(arrEl);
              });
              newArr.push({ title: el.show, score: el.score });
              return newArr;
            });
            foundAnswer = true;
          }
        });
      }
      if (!foundAnswer && !alreadyFound) {
        setCorrectAnswers((prevArr) => {
          let newArr = [];
          prevArr.forEach((arrEl) => {
            newArr.push(arrEl);
          });
          newArr.push({ title: ans, score: 0 });
          return newArr;
        });
      }
      inputEl.current.value = "";
    }
  }
  return (
    <div className="game-page">
      <img className="game-background" style={{left:"-10px",animationDuration:"15s"}} src={sideSvgL} alt="background"></img>
      <img className="game-background" style={{right:"-10px",animationDuration:"20s"}}src={sideSvg} alt="background"></img>
      <div className="game-container">
        <Popup trigger= {<img src="./circle-question-solid.svg" className="info-wrapper"/>} modal nested>
          {
            close => (
              <div className="modal-wrapper">
                <img className="close-button" src={arrow} onClick= {() => close()} />
                {locales[lang].game.description}
              </div>
            )
          }
        </Popup>
        <div className="game-header">
          {gameState === 1 && <p>{gameTopic.title}</p>}
          {gameState === 1 && <p>{timer}</p>}
        </div>

        <div className="game-box">
          {correctAnswers.map((answer) => {
            return (
              <div className="answer-row">
                <span>{answer.title}</span>
                <span
                  style={{
                    backgroundImage: `linear-gradient(90deg,var(--alt1) 0%, var(--alt1) ${
                      80 - answer.score * 30
                    }%, var(--main) 100%)`,
                  }}
                >
                  {answer.score}
                </span>
              </div>
            );
          })}
        </div>
        {gameState === 0 && (
          <button className="game-start" onClick={startGame}>
            START GAME
          </button>
        )}
        {gameState === 2 && (
          <div className="game-finished-container">
            <div className="game-highscore-container">
              <p>You got {score} points</p>
              <p>Your high score for this category is {highScore} points</p>
            </div>
            <button className="game-start" onClick={startGame}>
              START GAME
            </button>
          </div>
        )}
        {gameState === 1 && (
          <div className="game-input-container">
            <input
              className="game-input"
              onKeyDown={(e) => {
                if (e.key === "Enter") handleSubmit();
              }}
              ref={inputEl}
              placeholder="Your answer..."
            ></input>
            <button onClick={handleSubmit} className="game-input-submit">
              <img src={buttonArrow}></img>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Game;
