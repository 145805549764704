import './OrangeGradientText.css'

function OrangeGradientText({ text, fontSize, fancy = false, reverse = false, offset = "3px" })
{
    return (
        <div className="text-wrapper">
            <div className={!reverse ? "orange-gradient-text" : "orange-gradient-text-reverse"} style={{fontSize: fontSize}}>
                {text}
            </div>
            {
                fancy ? <div className="orange-gradient-text text-background" style={{fontSize: fontSize, top: offset, left: offset}}>
                    {text}
                </div> : null
            }
        </div>
    )
}


export default OrangeGradientText